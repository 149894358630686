import type {NextPage} from 'next';
import Head from 'next/head';
import {Box, Container, Typography, Unstable_Grid2 as Grid} from "@mui/material";
import React from "react";
import {Layout} from "../layouts";
import {useSettings} from "../hooks/use-settings";
import {OverviewStats} from "../components/overview/overview-stat";
import PersonIcon from "@mui/icons-material/Person";
import {paths} from "../paths";
import SchoolIcon from "@mui/icons-material/School";
import GroupsIcon from "@mui/icons-material/Groups";
import {useStatsRetrieveQuery} from "../api/stats";
import {OverviewApplicationCount} from "../components/overview/overview-application-count";
import {OverviewLastApplications} from "../components/overview/overview-last-applications";
import {OverviewOpenSessions} from "../components/overview/overview-open-sessions";
import {OverviewApplicationTypes} from "../components/overview/overview-application-types";

const Home: NextPage = () => {
  const settings = useSettings()

  const {stats} = useStatsRetrieveQuery(undefined, {
    selectFromResult: ({data}) => ({
      stats: (data?.model_count ?? {application: 0, session: 0, major: 0})
    })
  })

  return (
    <>
      <Head>
        <title>
          Mystes - Tableau de bord
        </title>
      </Head>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2
        }}
      >
        <Container maxWidth={settings.stretch ? false : 'xl'}>
          <Grid
            container
            disableEqualOverflow
            spacing={{
              xs: 3,
              lg: 4
            }}
          >
            <Grid xs={12} >
              <Typography variant="h4">
                Tableau de bord
              </Typography>
            </Grid>
            <Grid
              xs={12}
              md={4}
            >
              <OverviewStats icon={ <PersonIcon fontSize="large" />} amount={stats.application} title="Candidatures" link={paths.applications.index} />
            </Grid>
            <Grid
              xs={12}
              md={4}
            >
              <OverviewStats icon={ <GroupsIcon fontSize="large" />} amount={stats.session} title="Promotions" link={paths.sessions.index} />
            </Grid>
            <Grid
              xs={12}
              md={4}
            >
              <OverviewStats icon={ <SchoolIcon fontSize="large" />} amount={stats.major} title="Formations" link={paths.majors.index} />
            </Grid>
            <Grid container xs={12} >
              <Grid
                xs={12} md={6}
              >
                <OverviewApplicationCount />
              </Grid>
              <Grid
                xs={12} md={6}
              >
                <OverviewApplicationTypes />
              </Grid>
            </Grid>
            <Grid container xs={12} md={8}>

              <Grid
                xs={12}
              >
                <OverviewLastApplications />
              </Grid>
            </Grid>
            <Grid container xs={12} md={4}>
              <Grid
                xs={12}
              >
                <OverviewOpenSessions />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

Home.getLayout = (page) => (
  <Layout>
    {page}
  </Layout>
);

Home.auth = true

export default Home;
