import type {FC} from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Button, Card, CardActions, Divider, Stack, SvgIcon, Typography} from '@mui/material';
import NavLink from "../NavLink";

interface OverviewStatsProps {
  amount: number;
  title: string;
  icon: React.ReactNode
  link: string
}

export const OverviewStats: FC<OverviewStatsProps> = (props) => {
  const { amount, icon, title, link } = props;

  return (
    <Card>
      <Stack
        alignItems="center"
        justifyContent="space-around"
        direction={{
          xs: 'column',
          sm: 'row'
        }}
        spacing={3}
        sx={{
          px: 4,
          py: 3
        }}
      >
        {icon}
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            color="text.primary"
            variant="h5"
          >
            {title}
          </Typography>
        </Box>
        <Typography
          color="primary"
          variant="h4"
        >
          {amount}
        </Typography>
      </Stack>
      <Divider />
      <CardActions>
        <NavLink href={link} color="inherit">
          <Button
            endIcon={(
              <SvgIcon>
                <ArrowForwardIcon />
              </SvgIcon>
            )}
            size="small"
          >
            Voir toutes les {title.toLowerCase()}
          </Button>
        </NavLink>
      </CardActions>
    </Card>
  );
};

OverviewStats.propTypes = {
  amount: PropTypes.number.isRequired
};
