import type {FC} from 'react';
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import NavLink from "../NavLink";
import {paths} from "../../paths";
import {useStatsRetrieveQuery} from "../../api/stats";

interface OverviewOpenSessionsProps {
}

export const OverviewOpenSessions: FC<OverviewOpenSessionsProps> = (props) => {

  const {stats} = useStatsRetrieveQuery(undefined, {
    selectFromResult: ({data}) => ({
      stats: (data?.open_applications ?? [])
    })
  })

  return (
    <Card>
      <CardHeader
        title="Inscriptions ouvertes"
        subheader="Par promotion"

      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              Promotion
            </TableCell>
            <TableCell align="center">
              Année
            </TableCell>
            <TableCell align="center">
              Candidatures
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stats.map(({session, count}) => {
            return (
              <TableRow
                key={session.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{py: 0.5}}>
                  <NavLink
                    href={{
                      pathname: paths.sessions.detail,
                      query: {id: session.id}
                    }}
                    color="text.primary"
                    variant="subtitle2"
                  >
                    {session.name}
                  </NavLink>
                </TableCell >
                <TableCell align="center" sx={{py: 0.5}}>
                  {session.year}
                </TableCell>
                <TableCell align="center" sx={{py: 0.5}}>
                  {count}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Divider />
      <CardActions>
        <NavLink
          href={paths.sessions.index}
          color="inherit"
        >
          <Button
            color="inherit"
            endIcon={(
              <SvgIcon>
                <ArrowForwardIcon />
              </SvgIcon>
            )}
            size="small"
          >
            Détails
          </Button>
        </NavLink>
      </CardActions>
    </Card>
  );
};

