import { mystesApi as api } from "./index";
export const addTagTypes = ["stats"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      statsRetrieve: build.query<StatsRetrieveApiResponse, StatsRetrieveApiArg>(
        {
          query: () => ({ url: `/api/v1/stats/` }),
          providesTags: ["stats"],
        }
      ),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as mystesApi };
export type StatsRetrieveApiResponse = /** status 200  */ Stats;
export type StatsRetrieveApiArg = void;
export type ModelStats = {
  application: number;
  session: number;
  major: number;
};
export type Session = {
  id: number;
  name: string;
  year?: number | null;
  number: number | null;
  accept_applications?: boolean;
  major: number;
};
export type OpenApplicationsStats = {
  session: Session;
  count: number;
};
export type MostActiveSessionsStats = {
  session: Session;
  count: number;
};
export type GraphSeries = {
  name: string;
  data: number[];
};
export type GraphStats = {
  series: GraphSeries[];
  axis: string[];
};
export type StateEnum = 0 | 1 | 2 | 3 | 4;
export type LastApplicationsStats = {
  candidate: string;
  candidate_id: string;
  session: string;
  session_id: number;
  received_date: string;
  state: StateEnum;
};
export type Stats = {
  model_count: ModelStats;
  open_applications: OpenApplicationsStats[];
  most_active_sessions: MostActiveSessionsStats[];
  yearly_applications: GraphStats;
  applications_types: GraphStats;
  last_applications: LastApplicationsStats[];
};
export const { useStatsRetrieveQuery, useLazyStatsRetrieveQuery } =
  injectedRtkApi;
