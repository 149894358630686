import type {FC} from 'react';
import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import NavLink from "../NavLink";
import {paths} from "../../paths";
import {useStatsRetrieveQuery} from "../../api/stats";
import {DateTime} from "luxon";
import {StateChip} from "../state-chip";
import {stateToLabel} from "../../utils/formatters";

interface OverviewLastApplicationsProps {
}

export const OverviewLastApplications: FC<OverviewLastApplicationsProps> = (props) => {

  const {stats} = useStatsRetrieveQuery(undefined, {
    selectFromResult: ({data}) => ({
      stats: (data?.last_applications ?? [])
    })
  })

  return (
    <Card>
      <CardHeader
        title="Dernières candidatures"
        subheader="Ordre décroissant"
      />
      <Table align="center">
        <TableHead>
          <TableRow>
            <TableCell>
              Candidat
            </TableCell>
            <TableCell align="center">
              Promotion
            </TableCell>
            <TableCell align="center">
              Date
            </TableCell>
            <TableCell align="center">
              État
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stats.map((element) => {
            return (
              <TableRow
                key={element.candidate_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{py: 1}}>
                  <NavLink
                    href={{
                      pathname: paths.applications.detail,
                      query: {id: element.candidate_id}
                    }}
                    color="text.primary"
                    variant="subtitle2"
                  >
                    {element.candidate}
                  </NavLink>
                </TableCell>
                <TableCell align="center" sx={{py: 1}}>
                  <NavLink
                    href={{
                      pathname: paths.sessions.detail,
                      query: {id: element.session_id}
                    }}
                    color="text.primary"
                    variant="subtitle2"
                  >
                    {element.session}
                  </NavLink>
                </TableCell>
                <TableCell align="center" sx={{py: 1}}>
                  {DateTime.fromISO(element.received_date).toLocaleString(DateTime.DATE_FULL)}
                </TableCell>
                <TableCell align="center" sx={{py: 1}}>
                  <StateChip state={element.state} state_name={stateToLabel(element.state)}/>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Divider />
      <CardActions>
        <NavLink
          href={paths.sessions.index}
          color="inherit"
        >
          <Button
            color="inherit"
            endIcon={(
              <SvgIcon>
                <ArrowForwardIcon />
              </SvgIcon>
            )}
            size="small"
          >
            Détails
          </Button>
        </NavLink>
      </CardActions>
    </Card>
  );
};

