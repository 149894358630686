import type {FC} from 'react';
import {useMemo} from "react";
import {Box, Card, CardHeader} from '@mui/material';
import {Chart} from '../chart';
import {useStatsRetrieveQuery} from "../../api/stats";
import {ApexOptions} from "apexcharts";
import {useTheme} from "@mui/material/styles";

interface OverviewApplicationCountProps {
}

export const OverviewApplicationCount: FC<OverviewApplicationCountProps> = (props) => {
  const theme = useTheme()
  const {stats} = useStatsRetrieveQuery(undefined, {
    selectFromResult: ({data}) => ({
      stats: (data?.yearly_applications ?? {series: [], axis: []})
    })
  })

  const chartOptions: ApexOptions = useMemo(() => ({
    chart: {
      background: 'transparent',
        stacked: false,
        toolbar: {
          show: false
        }
    },
    colors: [
      theme.palette.primary.main,
      theme.palette.mode === 'dark'
        ? theme.palette.primary.darkest
        : theme.palette.primary.light
    ],
    dataLabels: {
      enabled: true
    },
    legend: {
      labels: {
        colors: theme.palette.text.secondary
      },
      onItemClick: {
        toggleDataSeries: false
      },
      onItemHover: {
        highlightDataSeries: false
      }
    },
    grid: {
      borderColor: theme.palette.divider,
        strokeDashArray: 2
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
          columnWidth: '32px'
      }
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      y: {
        formatter: (value: number): string => `${value} candidatures`
      }
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      categories: stats.axis,
        labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: {
      labels: {
        show: true
      }
    }
  }), [stats.axis, theme.palette.divider, theme.palette.mode, theme.palette.primary.darkest, theme.palette.primary.light, theme.palette.primary.main, theme.palette.text.secondary]);

  return (
    <Card>
      <CardHeader
        title="Candidatures par formation"
        subheader="Sur les 2 dernières années"
      />
      <Box sx={{ height: 336 }}>
        <Chart
          height={300}
          options={chartOptions}
          series={stats.series}
          type="bar"
        />
      </Box>
    </Card>
  );
};